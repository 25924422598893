import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/warsztaty-spe-aurabusiness.jpg";
import Lightbox from "../components/lightBox";

export const query = graphql`
  {
    warsztat2: file(
      relativePath: { eq: "warsztat-recepta-na-rozwoj-biznesu.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    warsztat1: file(
      relativePath: {
        eq: "flow-consulting-warsztat-recepta-na-rozwoj-biznesu.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    warsztat3: file(relativePath: { eq: "spe-warsztat.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    warsztat9: file(
      relativePath: { eq: "warsztat-spe-justin-roff-marsh.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    warsztat6: file(
      relativePath: { eq: "lukasz-tadyszak-justin-roff-marsh.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    warsztat10: file(
      relativePath: { eq: "spe-justin-roff-marsh-warsztat-polska.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    warsztat7: file(
      relativePath: { eq: "lukasz-tadyszak-spe-aurabusiness.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    warsztat8: file(relativePath: { eq: "aurabusiness-spe.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Specrm = ({ data }) => {
  return (
    <Artykul
      title="Warsztaty “Recepta na rozwój biznesu“"
      keywords={[""]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Rola technologii w zarządzaniu sprzedażą i obsługą klienta"
      metaTitle="Sales Process Engineering i wsparcie rozwiązań IT - warsztaty"
      metaDescription="Questy partnerem merytorycznym warsztatów “Recepta na rozwój biznesu“ ✅ Rola technologii w zarządzaniu sprzedażą i obsługą klienta ✅ Metoda Sales Process Engineering"
    >
      <br />
      <p>
        W drugiej połowie października 2019 r., w Łodzi i Wrocławiu, odbyły się
        dwa wyjątkowe wydarzenia. Do Polski przyjechał Justin Roff-Marsh, autor
        metody Sales Process Engineering (SPE) i bestsellerowej książki "The
        Machine". Wspólnie mieliśmy okazję wystąpić na warsztatach "Recepta na
        rozwój biznesu" organizowanych przez firmę{" "}
        <a href="https://flow-consulting.pl/">Flow Consulting</a>.
      </p>
      <p>
        Podczas warsztatów <strong>Justin Roff Marsh</strong> opowiadał m.in. o:
        <ul>
          <li>opracowaniu struktury rozwoju organizacji,</li>
          <li>projektowaniu modelu sprzedaży,</li>
          <li>integracji sprzedaży i marketingu,</li>
          <li>a także roli kadry zarządzającej.</li>
        </ul>
      </p>
      <p>
        <strong>Łukasz Tadyszak</strong>, CEO Questy, mówił o roli rozwiązania{" "}
        <a href="https://aurabusiness.pl">Aura Business</a> w kontekście SPE
        oraz podzielił się z uczestnikami doświadczeniem z wdrożenia tego
        podejścia w naszej organizacji. W swojej prezentacji najwięcej uwagi
        poświęcił czterem głównym wyzwaniom, z jakimi musiał się zmierzyć
        wdrażając Sales Process Engineering:
        <ul>
          <li>skalowanie a podział pracy,</li>
          <li>synchronizacja obszaru dostarczania i sprzedaży,</li>
          <li>koordynacja procesów sprzedaży,</li>
          <li>oraz synchronizacja marketingu i sprzedaży.</li>
        </ul>
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
        }}
        images={[
          data.warsztat2,
          data.warsztat1,
          data.warsztat3,
          data.warsztat9,
          data.warsztat10,
          data.warsztat6,
          data.warsztat7,
          data.warsztat8,
        ]}
        alts={[
          "sales process engineering, wsparcie technologii w zarządzaniu sprzedażą, warsztaty z sales process engineering, aura business i sales process engineering ",
        ]}
      />
      <br />
      <p>
        Jeżeli jesteś CEO, dyrektorem lub kierownikiem i chcesz dowiedzieć się,
        w jaki sposób nasza technologia może wesprzeć synchronizację głównych
        procesów w Twoim przedsiębiorstwie to zapraszamy do niezobowiązującej
        rozmowy. Zostaw kontakt do siebie - <a href="/kontakt">tutaj</a>.
      </p>
    </Artykul>
  );
};

export default Specrm;
